.footer {
  margin-top: 18px;
  color: #9A9A9A;
  font-size: 14px;
  text-align: center;
  position: absolute;
  bottom: 25px;
  width: 90vw;
  @media (min-width: 768px){
    font-size: 18px;
    text-align: left;
  }
  @media (max-height: 650px){
    display: none !important;
  }
  a {
    text-decoration: none;
    color: #9A9A9A;
    font-size: 14px;
    @media (min-width: 768px){
      font-size: 18px;
    }
  }
  a:hover {
    color: #3f3250;
  }
}