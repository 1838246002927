.hub-and-house-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media(min-width: 768px) {
    align-items: flex-start;
  }
  .content {
    width: 90%;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    @media(min-width: 768px) {
      width: 100%;
      padding-left: 80px;
    }
    .house-choices {
      width: 90%;
      height: 100px;
      display: flex;
      align-items: center;
      @media(min-width: 768px) {
        width: 100%;
        margin-top: 40px;
      }
      .house-choice-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 25px;
        opacity: 0.35;
        @media(min-width: 768px) {
          margin-right: 35px;
        }
        .house-choice {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 5px solid #03DB80;
          color: #E7E7E7;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          padding: 5px;
          font-size: 30px;
          @media(min-width: 768px) {
            width: 80px;
            height: 80px;
            padding-right: 15px;
            padding-top: 10px;
          }
          .house-svg {
            height: 90%;
            width: 90%;
            @media(min-width: 768px) {
              height: 120%;
              width: 120%;
            }
          }
        }
        .house-name {
          color: rgb(48, 35, 65);
          @media(min-width: 768px) {
            font-size: 18px;
            margin-top: 6px;
          }
        }
      }
      .active {
        opacity: 1;
      }
    }
    .house-and-hub-info-container {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      width: 100%;
      border-radius: 10px;
      padding: 15px;
      background-color:#FBFBFB;
      @media(min-width: 768px) {
        font-size: 20px;
        margin-right: 30px;
        margin-top: 30px;
      }
      .plan-name {
        color: #7B7B7B;
        font-size: 24px;
        margin-bottom: 4px;
        @media(min-width: 768px) {
          font-size: 28px;
        }
      } 
      .info-detail, .info-detail-italic {
        margin-top: 10px;
        color: #22252C;
        font-size: 16px;
        @media(min-width: 768px) {
          font-size: 20px;
        }
      }
      .info-detail-italic {
        font-style: italic;
        text-decoration: underline;
      }
      .action-button-container {
        display: flex;
        margin-top: 15px;
        @media(min-width: 768px) {
          margin-top: 25px;
        }
        .action-button-container-content {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 50px;
          .upgrade-button {
            display: flex;
            justify-content: center;
            width: 110px;
            align-items: center;
            height: 40px;
            background: #3F3250 0% 0% no-repeat padding-box;
            border-radius: 20px;
            font-size: 18px;
            letter-spacing: 0;
            color: #FFFFFF;
            cursor: pointer;
            @media(min-width: 768px) {
              width: 180px;
              height: 55px;
              background: #3F3250 0% 0% no-repeat padding-box;
              border-radius: 30px;
              font-size: 24px;
            }
          }
          .upgrade-button:active {
            transform: translate(2px, 2px);
          }
        }
      }
      .hubs-container {
        // display: flex;
        // flex-direction: column;
        width: 100%;
        height: 215px;
        max-height: 215px;
        margin-top: 30px;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch; 
        @media(min-width: 768px) {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 98%;
          height: fit-content;
          margin-top: 22.5px;
        }
        .hub-container, .hub-container0 {
          font-size: 50px;
          background-color: #F1F1F1;
          width: 100%;
          height: 85px;
          display: flex;
          align-items: center;
          padding: 10px;
          border-radius: 5px;
          color: #22252C;
          margin-top: 10px;
          @media(min-width: 768px) {
            width: 49%;
          }
          .hub-svg {
            height: 50%;
            display: flex;
            align-items: center;
          }
          .hub-info-container {
            display: flex;
            flex-direction: column;
            margin-left: 15px;
            .hub-name {
              font-size: 18px;
              color: #22252C;
            }
            .hub-info-detail {
              font-size: 16px;
              color: #696969;
            }
          }
        }
        .hub-container0 {
          margin-top: 0px;
          @media(min-width: 768px) {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.Toastify__toast-body {
  color: #3F3250;
}

.Toastify__close-button, .Toastify__close-button--default {
  color: #3F3250 !important;
  opacity: 1 !important;
}