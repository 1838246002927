.account-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(min-width: 768px) {
    align-items: flex-start;
  }
  .content-container {
    width: 90%;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    @media(min-width: 768px) {
      width: 100%;
      padding-left: 80px;
    }
    .title {
      font-size: 28px;
      font-weight: bold;
      letter-spacing: 0;
      color: #222222;
      margin-top: 30px;
      @media(min-width: 768px) {
        font-size: 36px;
        margin-top: 70px;
      }
    }
    .user-info-content-container {
      padding: 15px;
      background: #FBFBFB 0% 0% no-repeat padding-box;
      margin-top: 25px;
      font-size: 16px;
      color: #22252C;
      border-radius: 10px;
      @media(min-width: 768px) {
        font-size: 20px;
        margin-right: 30px;
      }
      .name {
        font-size: 22px;
        color: #707070;
        @media(min-width: 768px) {
          font-size: 28px;
        }
      }
      .info-detail-1 {
        margin-top: 15px;

      }
      .info-detail {
        margin-top: 8px;
      }
      .info-detail-homes {
        display: flex;
        align-items: flex-start;
        margin-top: 6px;
        .info-detail-title {

        }
        .homes-container {
          margin-left: 6px;
          .more {
            text-decoration: underline;
            font-style: italic;
          }
          .home-name {

          }
        }
      }
      .update-info-button {
        margin-top: 20px;
        background: #3F3250 0% 0% no-repeat padding-box;
        border-radius: 20px;
        width: 130px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 18px;
        cursor: pointer;
        @media(min-width: 768px) {
          font-size: 24px;
          width: 180px;
          height: 55px;
          border-radius: 30px;
        }
      }
      .update-info-button:active {
        transform: translate(2px, 2px);
      }
    }
    .password-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px 15px;
      margin-top: 20px;
      border-radius: 10px;
      background: #FBFBFB 0% 0% no-repeat padding-box;
      @media(min-width: 768px) {
        font-size: 20px;
        margin-right: 30px;
      }
      .info-detail {
      }
      .last-update {
        margin-top: 8px;
      }
      .change-password-button {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 173px;
        height: 40px;
        background: #3F3250 0% 0% no-repeat padding-box;
        font-size: 18px;
        border-radius: 20px;
        margin-top: 10px;
        cursor: pointer;
        @media(min-width: 768px) {
          width: 250px;
          font-size: 24px;
          height: 55px;
          border-radius: 30px;
          margin-top: 15px;
        }
      }
      .change-password-button:active {
        transform: translate(2px, 2px);
      }
    }
  }
}