.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: absolute;
  background-color: white;
  height: 100%;
  width: 100%;
  color: #222222;
  top: 0;
  left: 0;
  bottom: 0;
  @media(min-width: 768px){
    justify-content: center;
  }
  .close {
    height: 20px;
    width: 20px;
    margin: 15px;
    cursor: pointer;
  }
  
  .close-container {
    display: none;
    @media(min-width: 768px) {
      justify-content: flex-end;
      display: flex;
    }
  }
  .modal-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    max-width: 966px;
    height: 100%;
    @media(min-width: 768px) {
      height: 95%;
      box-shadow: 0px 3px 6px #00000029;
      padding: 15px;
      border-radius: 10px;
    }
    .modal-header {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      height: 60px;
      .back-container {
        height: 100%;
        display: flex;
        align-items: center;
        @media(min-width: 768px) {
          display: none;
        }
        .back {
          position: absolute;
          left: 5%;
          border: solid #222222;
          border-width: 0 4px 4px 0;
          display: inline-block;
          transform: rotate(135deg);
          height: 18px;
          width: 18px;
          cursor: pointer;
        }
      }
      .title {
        font-size: 28px;
        font-weight: bold;
        @media(min-width: 768px) {
          font-size: 30px;
        }
      }
    }
    .inputs-container, .name-inputs-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 5px;
      @media(min-width: 768px) {
        margin-top: 15px;
      }
      .input-container, .name-input-container {
        margin-top: 15px;
        max-width: 600px;
        .label {
          font-size: 16px;
          letter-spacing: 0;
          color: rgb(138, 138, 138);
          display: flex;
        }
        .input, .name-input {
          width: 100%;
          height: 55px;
          background: #FBFBFB 0% 0% no-repeat padding-box;
          border: 1px solid #E2E2E2;
          border-radius: 5px;
          padding-left: 10px;
          margin-top: 5px;
          font-size: 20px;
          color: #222222;
        }
      }
      .name-input-container {
        width: 48%;
      }
    }
    .name-inputs-container {
      max-width: 600px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .update-info-button-container {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      @media(min-width: 768px) {
        justify-content: flex-end;
      }
      .update-info-button {
        width: 335px;
        height: 50px;
        background: #3F3250 0% 0% no-repeat padding-box;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 22px;
        cursor: pointer;
        @media(min-width: 768px) {
          width: 160px;
          height: 55px;
          border-radius: 5px;
          font-size: 21px;
        }
      }
      .update-info-button:active {
        transform: translate(2px, 2px);
      }
    }
  }
}