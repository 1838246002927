.upgrade-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: absolute;
  background-color: white;
  height: 100%;
  width: 100%;
  color: #222222;
  top: 0;
  left: 0;
  bottom: 0;
  @media(min-width: 768px){
    justify-content: center;
    flex-direction: row;
  }
  .upgrade-modal-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    max-width: 966px;
    height: 100%;
    @media(min-width: 768px) {
      height: 95%;
      box-shadow: 0px 3px 6px #00000029;
      padding: 15px;
      border-radius: 10px;
    }
    .close-container {
      display: none;
      @media(min-width: 768px) {
        justify-content: flex-end;
        display: flex;
      }
      .close {
        height: 20px;
        width: 20px;
        margin: 15px;
        cursor: pointer;
      }
    }
    .modal-header {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      height: 60px;
      .back-container {
        height: 100%;
        display: flex;
        align-items: center;
        @media(min-width: 768px) {
          display: none;
        }
        .back {
          position: absolute;
          left: 5%;
          border: solid #222222;
          border-width: 0 4px 4px 0;
          display: inline-block;
          transform: rotate(135deg);
          height: 18px;
          width: 18px;
          cursor: pointer;
        }
      }
      .title {
        font-size: 28px;
        font-weight: bold;
        @media(min-width: 768px) {
          font-size: 30px;
          margin-bottom: 10px;
          width: 800px;
          text-align: center;
        }
      }
    }
    .subscriptions-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 85%;
      max-height: 85%;
      overflow-y: scroll;
      @media(min-width: 768px) {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
      }
      .subscriptions {
        width: 100%;
        max-width: 800px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media(min-width: 768px) {
          justify-content: space-between;
          flex-wrap: wrap;
          flex-direction: row;
        }
      }
      .plan-desc-container {
        width: 100%;
        max-width: 800px;
        color: #222222;
        margin-top: 20px;
        .plan-desc-title {
          font-size: 20px;
          font-weight: 600;
          display: flex;
        }
        .details-container {
          margin-top: 10px;
          .plan-desc-detail {
            margin-top: 5px;
            display: flex;
            font-size: 16px;
            display: flex;
            align-items: center;
            .check {
              width: 25px;
            }
            .check {
              font-size: 20px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
    .update-info-button-container {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      @media(min-width: 768px) {
        justify-content: flex-end;
      }
      .update-info-button {
        width: 335px;
        height: 50px;
        background: #3F3250 0% 0% no-repeat padding-box;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 22px;
        cursor: pointer;
        @media(min-width: 768px) {
          width: 160px;
          height: 55px;
          border-radius: 5px;
          font-size: 21px;
        }
      }
      .update-info-button:active {
        transform: translate(2px, 2px);
      }
    }
  }
}

.subscription-close-container {
  justify-content: flex-end;
  display: flex;
  width: 100%;
  .close {
    height: 15px;
    width: 15px;
    cursor: pointer;
  }
}